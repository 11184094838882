.App {
  text-align: center;
  position: relative;
}

form {
  padding: 20px;
}

.puffloader-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

h2 {
  margin-top: 10px;
  margin-bottom: 20px;
}

h1 {
  color: #80dbce;
}

body {
  background-color: #141414;
  color: #ffead0;
}
