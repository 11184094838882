.flex {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

.flex-justify-center {
  justify-content: center;
  align-items: center;
}
