.fab {
    position: absolute !important;
    top: 10px;
    right: 10px;
}

.buttons {
    > button {
        width: 92px;
    }
}