.text-score {
    color: #80dbce;
    font-weight: bold;
    margin-left: 8px;
}

.text-deduction {
    color: #FF6B6B;
    
    margin-left: 8px;
}